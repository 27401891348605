import styled from "styled-components";

import { AssetData } from "../app/helpers/get-asset-data";
import ComingSoon from "./ComingSoon";
import useTokenPrice from "../app/web3/views/use-token-price";

const Icon = styled.img`
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--sub);
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.7rem;
`;

const Name = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
`;

const Symbol = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--sub);
  text-align: left;
`;

const Value = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  margin-right: 0.8rem;
`;

interface OptionProps {
  $isOnly: boolean;
  $comingSoon?: boolean;
}

const Option = styled.button<OptionProps>`
  cursor: ${({ $comingSoon }) => ($comingSoon ? "not-allowed" : "pointer")};
  width: 100%;
  height: 4rem;
  padding: 0.4rem;
  margin-top: 0.8rem;
  border-radius: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.$isOnly ? "var(--bg-2)" : "none")};

  &:hover {
    background: var(--bg-2);
  }
`;

const OptionGroup = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const IconElement = ({ option }: { option?: AssetData }) => {
  if (!option || typeof option.icon === "string") {
    return <Icon src={option?.icon as string} />;
  }
  return option.icon;
};

interface Props {
  onlyOneOption: boolean;
  option: AssetData;
  setActive: (assetId: string) => void;
  close: () => void;
}

const AssetOption = ({ onlyOneOption, option, setActive, close }: Props) => {
  const defiLlamaPrice = useTokenPrice(option.address);
  const price = option.price || defiLlamaPrice;

  return (
    <ComingSoon comingSoon={option.comingSoon}>
      <Option
        $isOnly={onlyOneOption}
        $comingSoon={option.comingSoon}
        disabled={option.comingSoon}
        onClick={() => {
          setActive(option.id);
          close();
        }}
      >
        <OptionGroup>
          <IconElement option={option} />
          <TextSection>
            <Name>{option.name}</Name>
            <Symbol>{option.symbol}</Symbol>
          </TextSection>
        </OptionGroup>
        {option.balance && !option.balance.isZero() && (
          <Value>
            {price ? option.balance.toUsdValue(price.toNumber()) : "$--"}
          </Value>
        )}
      </Option>
    </ComingSoon>
  );
};

export default AssetOption;
