import styled from "styled-components";
import Box from "@mui/material/Box";

import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";

const StyledLoadingSpinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

interface Props {
  show: boolean;
  white?: boolean;
  small?: boolean;
}

const LoadingSpinner = ({ show, white, small }: Props) => {
  if (!show) return null;

  const size = small ? 23 : 60;
  const thickness = small ? 4 : 5;

  return (
    <StyledLoadingSpinner>
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: white ? "#585858" : "#213235",
          }}
          size={size}
          thickness={thickness}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: white ? "var(--main)" : "var(--primary)",
            animationDuration: "1050ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={size}
          thickness={thickness}
        />
      </Box>
    </StyledLoadingSpinner>
  );
};

export default LoadingSpinner;
