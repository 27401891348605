import discord from "../../assets/socials/discord.svg";
import twitter from "../../assets/socials/twitter.svg";
import blog from "../../assets/socials/blog.svg";
import github from "../../assets/socials/github.svg";

import { DISCORD, GITHUB, BLOG, TWITTER } from "./urls";

export interface SocialType {
  icon: string;
  name: string;
  url: string | null;
}

export const SOCIALS: SocialType[] = [
  {
    icon: twitter,
    name: "Twitter",
    url: TWITTER,
  },
  {
    icon: discord,
    name: "Discord",
    url: DISCORD,
  },
  {
    icon: blog,
    name: "Blog",
    url: BLOG,
  },
  {
    icon: github,
    name: "Github",
    url: GITHUB,
  },
];
