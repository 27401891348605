import styled from "styled-components";
import { CardFrame, SubCardFrame } from "../../styles/Frames";
import Chart from "../../components/Chart";
import susd from "../../assets/ui/susd.svg";
import { LeveragedTokenDataType } from "../../app/web3/views/use-leveraged-token-data";
import { ScaledNumber } from "scaled-number";
import { PNL_LIVE } from "../../app/constants/config";
import round from "../../app/helpers/round";

const StylePortfolioReturns = styled(CardFrame)<{ $pnlLive: boolean }>`
  display: flex;
  width: 100%;
  padding: 1.6rem;

  @media (max-width: 900px) {
    display: ${({ $pnlLive }) => ($pnlLive ? "flex" : "none")};
  }
`;

const Column = styled.div<{ $pnlLive: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.4rem;

  width: ${({ $pnlLive }) => ($pnlLive ? "auto" : "100%")};

  > :first-child {
    margin-bottom: ${({ $pnlLive }) => ($pnlLive ? "4rem" : "0")};
  }

  @media (max-width: 900px) {
    padding: 0;
    width: 100%;

    > :first-child {
      margin-bottom: 2.4rem;
    }
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5rem;

  @media (max-width: 900px) {
    margin-right: 0;
  }
`;

const Header = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  color: var(--sub);
  margin-bottom: 0.4rem;

  @media (max-width: 900px) {
    font-size: 2.2rem;
  }
`;

const Apr = styled.div`
  font-size: 3.2rem;
  font-weight: 500;
  font-size: 4rem;
  font-weight: 400;
  color: var(--success);
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const SmallRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const PortfolioValue = styled.div`
  font-size: 3.2rem;
  font-weight: 500;
  margin-right: 0.8rem;

  @media (max-width: 900px) {
    font-size: 3rem;
    margin-right: 0.7rem;
  }
`;

const PortfolioValueIcon = styled.img`
  height: 2.4rem;
  margin-right: 0.8rem;
`;

const ChartContainer = styled(SubCardFrame)`
  flex: 1;

  @media (max-width: 900px) {
    display: none;
  }
`;

interface Props {
  leveragedTokens: LeveragedTokenDataType[] | undefined;
}

const PortfolioReturns = ({ leveragedTokens }: Props) => {
  const portfolioValue = leveragedTokens?.reduce((acc, token) => {
    return acc.add(token.userBalance.mul(token.exchangeRate));
  }, ScaledNumber.fromUnscaled(0));

  const averageLeverage =
    portfolioValue &&
    leveragedTokens
      ?.reduce((acc, token) => {
        return acc.add(
          token.leverage.mul(token.userBalance).mul(token.exchangeRate)
        );
      }, ScaledNumber.fromUnscaled(0))
      .div(portfolioValue);

  const bullishNotional = leveragedTokens
    ?.filter((token) => token.isLong)
    .reduce((acc, token) => {
      return acc.add(
        token.userBalance.mul(token.exchangeRate).mul(token.leverage)
      );
    }, ScaledNumber.fromUnscaled(0));

  const bearishNotional = leveragedTokens
    ?.filter((token) => !token.isLong)
    .reduce((acc, token) => {
      return acc.add(
        token.userBalance.mul(token.exchangeRate).mul(token.leverage)
      );
    }, ScaledNumber.fromUnscaled(0));

  const totalNotional =
    bullishNotional && bearishNotional
      ? bullishNotional.add(bearishNotional)
      : null;

  const uniqueAssets = leveragedTokens?.reduce((acc, token) => {
    if (!acc.includes(token.targetAsset)) {
      acc.push(token.targetAsset);
    }
    return acc;
  }, [] as string[]);

  const mainExposure = uniqueAssets?.reduce(
    (acc, asset) => {
      const assetNotional = leveragedTokens
        ?.filter((token) => token.targetAsset === asset)
        .reduce((acc, token) => {
          return acc.add(
            token.userBalance.mul(token.exchangeRate).mul(token.leverage)
          );
        }, ScaledNumber.fromUnscaled(0));

      if (assetNotional && assetNotional.gt(acc.notional)) {
        return { asset, notional: assetNotional };
      }

      return acc;
    },
    { asset: "", notional: ScaledNumber.fromUnscaled(0) }
  ).asset;

  return (
    <StylePortfolioReturns $pnlLive={PNL_LIVE}>
      <Column $pnlLive={PNL_LIVE}>
        {PNL_LIVE && (
          <Section>
            <Header>Percent Returns</Header>
            <Apr>{"+34.65%"}</Apr>
          </Section>
        )}
        <Row>
          {PNL_LIVE && (
            <Section>
              <Header>Total Returns</Header>
              <Row>
                <PortfolioValue>{"100.00"}</PortfolioValue>
                <PortfolioValueIcon src={susd} alt="sUSD" />
              </Row>
            </Section>
          )}
          <Section>
            <Header>Portfolio Value</Header>
            <SmallRow>
              <PortfolioValue>
                {portfolioValue
                  ? round(portfolioValue.toNumber(), 2).toLocaleString()
                  : "---"}
              </PortfolioValue>
              <PortfolioValueIcon src={susd} alt="sUSD" />
            </SmallRow>
          </Section>
          {!PNL_LIVE && (
            <>
              <Section>
                <Header>Long/Short</Header>
                <PortfolioValue>
                  {bullishNotional && bearishNotional && totalNotional
                    ? `${bullishNotional
                        .div(totalNotional)
                        .mul(100)
                        .toNumber()
                        .toFixed(0)}/${bearishNotional
                        .div(totalNotional)
                        .mul(100)
                        .toNumber()
                        .toFixed(0)}`
                    : "--/--"}
                </PortfolioValue>
              </Section>
              <Section>
                <Header>Average Leverage</Header>
                <PortfolioValue>{`${
                  averageLeverage ? averageLeverage.toNumber().toFixed(1) : "--"
                }x`}</PortfolioValue>
              </Section>
              <Section>
                <Header>Main Exposure</Header>
                <PortfolioValue>
                  {portfolioValue && portfolioValue.isZero()
                    ? "N/A"
                    : mainExposure
                    ? mainExposure
                    : "---"}
                </PortfolioValue>
              </Section>
            </>
          )}
        </Row>
      </Column>
      {PNL_LIVE && (
        <ChartContainer>
          <Chart chart={{ prices: [] }} />
        </ChartContainer>
      )}
    </StylePortfolioReturns>
  );
};

export default PortfolioReturns;
