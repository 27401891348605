import styled from "styled-components";

export const GlossFrame = styled.div<{ $active?: boolean }>`
  background: ${({ $active }) =>
    $active === undefined || $active === true
      ? "linear-gradient(to right, #141a18, #14191a) padding-box, linear-gradient(to right, var(--secondary), var(--primary)) border-box"
      : "linear-gradient(var(--bg-1), var(--bg-1)) padding-box, linear-gradient(to bottom, var(--sub), var(--sub)) border-box"};
  border: 1px solid transparent;
  box-shadow: var(--box-shadow);
`;

export const CardFrame = styled.div`
  background: var(--bg-1);
  border: var(--border);
  border-radius: 2.4rem;
  box-shadow: var(--box-shadow);
`;

export const SubCardFrame = styled.div`
  background: linear-gradient(#202020, #1c1c1c) padding-box,
    linear-gradient(to bottom, #3f3f3f, #272727) border-box;
  border: 1px solid transparent;
  width: 100%;

  box-shadow: var(--box-shadow);
  border-radius: 2.4rem;
  padding: 1.6rem 2rem;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem;
  justify-items: center;

  @media (max-width: 900px) {
    padding: 1.4rem 2rem;
    grid-gap: 2rem;
  }
`;
