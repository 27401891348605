import Banner from "./Banner";
import { OP_INCENTIVES_BLOG } from "../app/constants/urls";

const SHOW_INCENTIVES_BANNER = false;

const END_DATE = "Dec. 30th";

const OpIncentivesBanner = () => {
  if (!SHOW_INCENTIVES_BANNER) return null;

  return (
    <Banner
      id="op-incentives-banner"
      dismisable
      label={`Every trade earns $OP rewards. From now until ${END_DATE}`}
      buttonText="Learn more"
      buttonAction={() => window.open(OP_INCENTIVES_BLOG, "_blank")?.focus()}
    />
  );
};

export default OpIncentivesBanner;
