import styled from "styled-components";

const StyledInfoBox = styled.div<{ $overlay?: boolean; $card?: boolean }>`
  width: 100%;
  padding: 0 1.6rem;
  border-radius: 1.6rem;
  background: ${({ $overlay, $card }) =>
    $card
      ? "linear-gradient(#202020, #1c1c1c) padding-box, linear-gradient(to bottom, #3f3f3f, #272727) border-box"
      : $overlay
      ? "var(--bg-1)"
      : "var(--bg)"};
  border: var(--border);
  display: flex;
  align-items: center;
  height: 6rem;
`;

const Icon = styled.img`
  height: 2.4rem;
`;

interface TextProps {
  $warning?: boolean;
  $primary?: boolean;
  $success?: boolean;
}

const Text = styled.div<TextProps>`
  margin-left: 1.6rem;
  font-size: 1.2rem;
  font-weight: 500;
  flex: 1;
  color: ${(props) =>
    props.$success
      ? "var(--success)"
      : props.$warning
      ? "var(--warning)"
      : props.$primary
      ? "var(--primary)"
      : "var(--sub)"};
`;

interface Props {
  text: string;
  icon: string;
  warning?: boolean;
  primary?: boolean;
  success?: boolean;
  overlay?: boolean;
  card?: boolean;
}

const InfoBox = ({
  text,
  icon,
  warning,
  primary,
  success,
  overlay,
  card,
}: Props) => {
  return (
    <StyledInfoBox $overlay={overlay} $card={card}>
      <Icon src={icon} alt="icon" />
      <Text $warning={warning} $primary={primary} $success={success}>
        {text}
      </Text>
    </StyledInfoBox>
  );
};

export default InfoBox;
