import styled from "styled-components";
import Button from "./Button";
import { useEffect, useState } from "react";

import { AssetData, getAssetData } from "../app/helpers/get-asset-data";
import { ALL_ASSET_IDS } from "../app/data/asset-metadata";
import AssetSelectorPopup from "./AssetSelectorPopup";

const StyledAssetSelector = styled.div`
  display: flex;
  height: 4.8rem;
  padding: 0.7rem;
  padding-right: 1.2rem;
  border: var(--border);
  border-radius: 2.4rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: var(--bg-1);
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
`;

const Icon = styled.img`
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--sub);
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.7rem;
  height: 100%;
`;

const Name = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.05;
`;

const Symbol = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--sub);
  text-align: left;
  line-height: 1.05;
`;

interface Props {
  options?: AssetData[];
  active: string;
  setActive: (assetId: string) => void;
}

const AssetSelector = ({ options, active, setActive }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (!options) {
    options = ALL_ASSET_IDS.map((option) => getAssetData(option)).sort((a, b) =>
      a.comingSoon ? 1 : b.comingSoon ? -1 : 0
    );
  }
  const activeAsset = options.find((option) => option.id === active);

  const firstId = options && options.length > 0 ? options[0].id : null;
  useEffect(() => {
    if (!active && firstId) {
      setActive(firstId);
    }
  }, [firstId, active, setActive]);

  return (
    <>
      <StyledAssetSelector>
        <LeftSection>
          <Icon src={activeAsset?.icon} />
          <TextSection>
            <Name>{activeAsset?.name || "---"}</Name>
            <Symbol>{activeAsset?.symbol || "---"}</Symbol>
          </TextSection>
        </LeftSection>
        <Button small action={() => setIsOpen(true)}>
          Change
        </Button>
      </StyledAssetSelector>
      <AssetSelectorPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        options={options}
        setActive={setActive}
      />
    </>
  );
};

export default AssetSelector;
