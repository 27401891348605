import styled from "styled-components";

import external from "../assets/ui/external.svg";

import { Link } from "react-router-dom";
import { SOCIALS } from "../app/constants/socials";
import { LINKS } from "../app/constants/links";

const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 8rem;

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1;
    padding: 2.4rem 0;
    margin-top: 6rem;
    width: auto;
    grid-gap: 2rem;
    margin-bottom: 7.2rem;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    margin: auto;
  }
`;

const MobileSection = styled(Section)`
  display: none;

  @media (max-width: 900px) {
    display: flex;
  }
`;

const SocialLink = styled.a`
  cursor: pointer;
  margin-right: 2.4rem;

  @media (max-width: 900px) {
    margin: 0 1.2rem;
  }
`;

const SocialImage = styled.img`
  height: 2.2rem;

  @media (max-width: 900px) {
    height: 2rem;
  }
`;

const LinkContainer = styled.div<{ $desktopOnly?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 2.4rem;

  @media (max-width: 900px) {
    margin: 0 1.2rem;
    display: ${(props) => (props.$desktopOnly ? "none" : "flex")};
  }
`;

const LinkExternal = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--sub);
  font-size: 1.6rem;
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const LinkIcon = styled.img`
  height: 1.6rem;
  margin-left: 0.6rem;
`;

const LinkInternal = styled(Link)`
  cursor: pointer;
  color: var(--sub);
  font-size: 1.6rem;
  font-weight: 500;
  white-space: nowrap;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Section>
        {SOCIALS.filter((social) => !!social.url).map((social) => {
          if (!social.url) throw new Error("Social url is not defined");
          return (
            <SocialLink
              key={social.name}
              href={social.url}
              target="_blank"
              rel="noreferrer"
            >
              <SocialImage src={social.icon} alt={social.name} />
            </SocialLink>
          );
        })}
      </Section>
      <Section>
        {LINKS.filter((link) => !!link.path).map((link) => {
          if (!link.path) throw new Error("Link path is not defined");
          return (
            <LinkContainer $desktopOnly={!link.external} key={link.name}>
              {link.external && (
                <>
                  <LinkExternal
                    href={link.path}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.name}
                  </LinkExternal>
                  <LinkIcon src={external} alt="External Link" />
                </>
              )}
              {!link.external && (
                <LinkInternal to={link.path}>{link.name}</LinkInternal>
              )}
            </LinkContainer>
          );
        })}
      </Section>
      <MobileSection>
        {LINKS.filter((link) => !!link.path && !link.external).map((link) => {
          if (!link.path) throw new Error("Link path is not defined");
          return (
            <LinkContainer key={link.name}>
              <LinkInternal to={link.path}>{link.name}</LinkInternal>
            </LinkContainer>
          );
        })}
      </MobileSection>
    </StyledFooter>
  );
};

export default Footer;
