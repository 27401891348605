import styled from "styled-components";
import Popup from "./Popup";
import Button from "./Button";
import { useEthers } from "@usedapp/core";
import Step from "./Step";
import LoadingSpinner from "./LoadingSpinner";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HOME_PATH,
  LOCK_PATH,
  MINT_PATH,
  PORTFOLIO_PATH,
  REWARDS_PATH,
  STAKE_PATH,
  VESTING_PATH,
} from "../app/constants/paths";

const PROTOCOL_PATHS = [
  STAKE_PATH,
  MINT_PATH,
  PORTFOLIO_PATH,
  LOCK_PATH,
  REWARDS_PATH,
  VESTING_PATH,
];

const StyledLogin = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 52rem;
  padding: 0 1.6rem;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
  }
`;

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { library, active, account, activateBrowserWallet, isLoading } =
    useEthers();

  const connected = active && !!account && !!library;

  const isProtocolPage = PROTOCOL_PATHS.includes(
    location.pathname.substring(1)
  );

  return (
    <Popup
      open={!connected && isProtocolPage}
      close={() => navigate(HOME_PATH)}
      header="Login"
    >
      <StyledLogin style={{ opacity: isLoading ? 0 : 1 }}>
        <Step completed={connected} label="Connect browser wallet">
          {!connected && (
            <Button primary action={activateBrowserWallet}>
              Connect
            </Button>
          )}
        </Step>
      </StyledLogin>
      <LoadingSpinner show={isLoading} />
    </Popup>
  );
};

export default Login;
