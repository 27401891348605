import styled from "styled-components";

import icon from "../assets/ui/kebab.svg";
import { SubCardFrame } from "../styles/Frames";
import { useState } from "react";

const StyledKebab = styled.div`
  position: relative;
`;

const CloseMenuButton = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: default;
`;

const Menu = styled(SubCardFrame)`
  position: absolute;
  top: 65%;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
  z-index: 1;
  width: 20rem;
  grid-gap: 0;

  @media (max-width: 900px) {
    right: 0;
    transform: none;
  }
`;

const MenuItem = styled.button`
  cursor: pointer;
  color: var(--main);
  font-size: 1.4rem;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 1.5rem;
  height: 3rem;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

const Button = styled.button`
  cursor: pointer;
`;

const Icon = styled.img`
  height: 3.2rem;
`;

interface KebabItemType {
  label: string;
  action: () => void;
}

interface Props {
  items: KebabItemType[];
}

const Kebab = ({ items }: Props) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <StyledKebab>
      <Button onClick={() => setShowMenu(!showMenu)}>
        <Icon src={icon} alt="Kebab Icon" />
      </Button>
      {showMenu && (
        <>
          <CloseMenuButton onClick={() => setShowMenu(false)} />
          <Menu>
            {items.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  item.action();
                  setShowMenu(false);
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </StyledKebab>
  );
};

export default Kebab;
