import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  LayoutPosition,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Colors } from "chart.js";
import { useEffect, useRef, useState } from "react";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import dateFormat from "dateformat";

import { ChartType } from "../app/hooks/use-chart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Colors,
  Filler
);

const StyledChart = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

interface Props {
  chart: ChartType;
  color?: string;
  simple?: boolean;
}

const Chart = ({ chart, simple, color }: Props) => {
  const [backgroundColor, setBackgroundColor] = useState<any>("transparent");
  const chartRef = useRef<ChartJSOrUndefined<"line", number[], string> | null>(
    null
  );

  const labels = chart.prices.map((price) => dateFormat(price.time, "d mmm"));

  let softColor = color || "rgb(0, 209, 255, 0.6)";
  color = color || "rgb(0, 209, 255, 1)";

  // Handle gradient colors
  if (color.includes("linear-gradient")) {
    const firstColor = color.split(",")[1].trim();
    color = firstColor;
    softColor = firstColor;
  }

  const position: LayoutPosition = "left";

  const values = chart.prices
    .sort((a, b) => a.time.getTime() - b.time.getTime())
    .map((price, index) => price.price);

  const firstValue = values[0];

  const options = {
    animation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        capBezierPoints: false,
      },
    },
    plugins: {
      filler: {
        propagate: true,
      },
      colors: {
        enabled: true,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        display: !simple,
        ticks: {
          color: "#E7E7E7",
          fontSize: 12,
          fontWeight: 400,
          callback: (val: any, index: number, values: any): string => {
            return index % 4 === 0 ? labels[index] : "";
          },
        },
      },
      y: {
        position,
        display: !simple,
        grid: {
          display: false,
        },
        ticks: {
          color: "#E7E7E7",
          fontSize: 12,
          fontWeight: 400,
          callback: (value: any, index: any, values: any) => {
            const percent = (value - firstValue) / firstValue;
            if (percent < -1) return "";
            return `${Math.round(percent * 100)}%`;
          },
        },
        afterDataLimits(scale: any) {
          // add 5% to both ends of range
          var range = scale.max - scale.min;
          var grace = range * 0.05;
          scale.max += grace;
          scale.min -= grace;
        },
      },
    },
  };

  useEffect(() => {
    if (document) {
      const element = document.getElementById("canvas");
      if (element) {
        var ctx = (element as any).getContext("2d");
        if (ctx) {
          const gradient = (ctx as any).createLinearGradient(
            0,
            0,
            0,
            simple ? 110 : ctx.canvas.height
          );
          gradient.addColorStop(0, softColor);
          gradient.addColorStop(0.65, "transparent");
          gradient.addColorStop(1, "transparent");
          setBackgroundColor(gradient);
        }
      }
    }
  }, [color, simple, softColor]);

  const data = {
    labels,
    datasets: [
      {
        label: "Price",
        data: values,
        borderColor: color,
        tension: 0.4,
        pointRadius: 0,
        fill: true,
        backgroundColor,
      },
    ],
  };

  return (
    <StyledChart>
      <Line id="canvas" ref={chartRef} data={data} options={options} />
    </StyledChart>
  );
};

export default Chart;
