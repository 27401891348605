import styled from "styled-components";

import { CardFrame } from "../../styles/Frames";
import Unlock from "./Unlock";

import susd from "../../assets/ui/susd.svg";
import external from "../../assets/ui/external-pink.svg";
import {
  GENESIS_LOCKER_DOCS,
  TLX_YIELD_SERVER,
} from "../../app/constants/urls";
import useTokenBalance from "../../app/web3/views/use-token-balance";
import { TLX_ADDRESS } from "../../app/constants/addresses";
import useTlxPrice from "../../app/web3/views/use-tlx-price";
import useLockerData from "../../app/web3/views/use-locker-data";
import { ScaledNumber } from "scaled-number";
import AprTooltip from "../../components/AprTooltip";
import OpIncentivesBanner from "../../components/OpIncentivesBanner";

const StyledLockerPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem;

  @media (max-width: 900px) {
    padding: 2rem;
  }
`;

const Container = styled(CardFrame)`
  display: flex;
  width: 100%;
  padding: 4rem;
  max-width: 72rem;

  > div:first-child {
    margin-right: 4rem;
  }

  @media (max-width: 900px) {
    padding: 2rem;
    flex-direction: column;

    > div:first-child {
      margin-right: none;
    }
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
`;

const Header = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  color: var(--sub);
`;

const Apr = styled.div`
  font-size: 3.2rem;
  font-weight: 500;
  font-size: 4rem;
  font-weight: 400;
  color: var(--success);
`;

const SubHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  color: var(--sub);
  margin-bottom: 4rem;
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 4rem;
`;

const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StatHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
`;

const StatValue = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 0.4rem;
`;

const StatSusdContaienr = styled.div`
  display: flex;
  align-items: center;
`;

const StatSusdValue = styled.div`
  font-size: 1.4rem;
  color: var(--sub);
  margin-right: 0.8rem;
`;

const StatSusdIcon = styled.img`
  height: 1.5rem;
`;

const DocsContainer = styled.div`
  display: flex;

  @media (max-width: 900px) {
    display: none;
  }
`;

const DocsText = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
`;

const DocsLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--main);
  text-decoration: underline;
  margin-left: 0.4rem;
  cursor: pointer;
`;

const ExternalIcon = styled.img`
  height: 1.2rem;
  margin-left: 0.2rem;
`;

const LockPage = () => {
  const tlxBalance = useTokenBalance(TLX_ADDRESS);
  const tlxPrice = useTlxPrice();
  const lockerData = useLockerData();

  return (
    <StyledLockerPage>
      <OpIncentivesBanner />
      <Container>
        <Row>
          <HeaderContainer>
            <Header>APR</Header>
            <AprTooltip codeLink={TLX_YIELD_SERVER} />
          </HeaderContainer>
          <Apr>0%</Apr>
          <SubHeader>In TLX rewards</SubHeader>
          <Column>
            <ColumnRow>
              <StatHeader>Your TLX</StatHeader>
              <StatValue>
                {tlxBalance
                  ? tlxBalance.lt(ScaledNumber.fromUnscaled("0.00000001"))
                    ? "0"
                    : tlxBalance.toCryptoString()
                  : "---"}
              </StatValue>
              <StatSusdContaienr>
                <StatSusdValue>
                  {tlxBalance && tlxPrice
                    ? tlxBalance.toUsdValue(tlxPrice.toNumber())
                    : "$---"}
                </StatSusdValue>
                <StatSusdIcon src={susd} alt="sUSD" />
              </StatSusdContaienr>
            </ColumnRow>
            <ColumnRow>
              <StatHeader>Your Locked TLX</StatHeader>
              <StatValue>
                {lockerData ? lockerData.userLocked.toCryptoString() : "---"}
              </StatValue>
              <StatSusdContaienr>
                <StatSusdValue>
                  {lockerData && tlxPrice
                    ? lockerData.userLocked.toUsdValue(tlxPrice.toNumber())
                    : "$---"}
                </StatSusdValue>
                <StatSusdIcon src={susd} alt="sUSD" />
              </StatSusdContaienr>
            </ColumnRow>
          </Column>
          <DocsContainer>
            <DocsText>Learn about locking in the </DocsText>
            <DocsLink href={GENESIS_LOCKER_DOCS} target="_blank">
              Docs <ExternalIcon src={external} alt="external link icon" />
            </DocsLink>
          </DocsContainer>
        </Row>
        <Row>
          <Unlock />
        </Row>
      </Container>
    </StyledLockerPage>
  );
};

export default LockPage;
