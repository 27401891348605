import styled from "styled-components";

const StyledSwitch = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  gap: 0.8rem;
  box-shadow: var(--box-shadow);
  height: 3.2rem;
  border-radius: 1.6rem;
  background: #161616;
  padding: 0.2rem;
  background: var(--bg-1);
`;

interface ButtonProps {
  $active: boolean;
}

const Button = styled.button<ButtonProps>`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.3rem;

  background: ${(props) => (props.$active ? "var(--bg-3)" : "var(--bg-1)")};
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

interface LabelProps {
  $active: boolean;
}

const Label = styled.p<LabelProps>`
  font-size: 1.6rem;
  font-weight: 500;
  color: ${(props) => (props.$active ? "var(--main)" : "var(--sub)")};
`;

interface Props {
  switches: string[];
  active: string;
  setActive: (active: string) => void;
}

const Switch = ({ switches, active, setActive }: Props) => {
  return (
    <StyledSwitch>
      {switches.map((label: string) => (
        <Button
          key={label}
          $active={active === label}
          onClick={() => setActive(label)}
        >
          <ButtonContent>
            <Label $active={active === label}>{label}</Label>
          </ButtonContent>
        </Button>
      ))}
    </StyledSwitch>
  );
};

export default Switch;
