import styled from "styled-components";
import LoadingSpinner from "./LoadingSpinner";
import { useState } from "react";
import { useEthers } from "@usedapp/core";
import { useDispatch } from "react-redux";
import { setError } from "../state/uiSlice";

interface StyledButtonProps {
  $primary?: boolean;
  color?: string;
  $wide?: boolean;
  $small?: boolean;
  $disabled?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  align-items: center;
  justify-content: center;
  height: 4rem;
  border-radius: 2rem;
  font-weight: 500;

  height: ${(props) => (props.$small ? "2.4rem" : "4rem")};
  padding: ${(props) => (props.$small ? "0 0.8rem" : "0 1.6rem")};
  font-size: ${(props) => (props.$small ? "1.4rem" : "1.6rem")};
  box-shadow: ${({ $disabled, $small }) =>
    $disabled || $small ? "none" : "var(--box-shadow)"};
  background: ${({ $small, $disabled, $primary }) => {
    if ($disabled) return "var(--bg-3)";
    if ($small) return "var(--bg-3)";
    if ($primary)
      return "linear-gradient(to right, var(--secondary), var(--primary))";
    return "var(--bg-3)";
  }};
  border: 1px solid transparent;

  color: ${(props) => {
    if (props.$disabled) return "var(--disabled-text)";
    if (props.$primary) return "var(--bg-1)";
    if (props.$small) return "var(--main)";
    if (props.color) return props.color;
    return "var(--main)";
  }};

  width: ${(props) => (props.$wide ? "100%" : "auto")};
  filter: brightness(1);

  transition: filter 0.3s ease-in-out;
  &:hover {
    filter: brightness(1.1);
  }

  @media (max-width: 900px) {
    font-size: 1.6rem;
    padding: 0 2.4rem;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
`;

const Icon = styled.img`
  height: 1.6rem;
  margin-right: 0.8rem;
`;

interface Props {
  children: React.ReactNode;
  action: () => Promise<void> | void;
  primary?: boolean;
  color?: string;
  wide?: boolean;
  small?: boolean;
  disabled?: boolean;
  icon?: string;
  loading?: boolean;
  web3?: boolean;
}

const Button = ({
  children,
  primary,
  action,
  color,
  wide,
  small,
  disabled,
  icon,
  loading,
  web3,
}: Props) => {
  const dispatch = useDispatch();
  const { account, active, activateBrowserWallet } = useEthers();
  const [loadingState, setLoadingState] = useState(false);

  const connected = active && account;

  const requiresConnection = web3 && !connected;

  const isLoading = (loading || loadingState) && !requiresConnection;

  return (
    <StyledButton
      color={color}
      $primary={primary || requiresConnection}
      $wide={wide}
      $small={small}
      $disabled={(disabled || isLoading) && !requiresConnection}
      onClick={async () => {
        if (requiresConnection) {
          activateBrowserWallet();
          return;
        }

        if (disabled) return;
        if (isLoading) return;

        setLoadingState(true);
        try {
          await action();
        } catch (e: any) {
          dispatch(setError({ message: e.message, source: "Button" }));
        }
        setLoadingState(false);
      }}
    >
      <ButtonContent style={{ opacity: isLoading ? 0 : 1 }}>
        {icon && <Icon src={icon} alt="icon" />}
        {requiresConnection ? "Connect Wallet" : children}
      </ButtonContent>
      <LoadingSpinner white small show={!!isLoading} />
    </StyledButton>
  );
};

export default Button;
